import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import PreviewModal from './PreviewModal';
import UploadHoldingsModal from './UploadHoldingsModal';
import { handleGraphqlMutation } from 'helpers';
import { convertDataToStringType, errorValidation, processExcel } from './utils';
import { VALIDATE_FUNDS, VALIDATE_INVESTORS } from './queries';

type Props = {
  isModalOpen: boolean;
  handleModalClick: any;
  onFileUpload: any;
  setSelectedFile?: any;
  selectedFile?: any;
  retryUpload?: any;
  setUploadError: any;
  uploadError: any;
};

const HoldingsModal = ({
  isModalOpen,
  handleModalClick,
  onFileUpload,
  setSelectedFile,
  selectedFile,
  setUploadError,
}: Props) => {
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [acceptedFileData, setAcceptedFileData] = useState<any>([]);
  const [incorrectDataList, setIncorrectDataList] = useState<any>([]);
  const [correctFundData, setCorrectFundData] = useState<any>([]);
  const [correctInvestorData, setCorrectInvestorData] = useState<any>([]);
  const [isValidFile, setIsValidFile] = useState<boolean>(false);

  const closeModal = () => {
    setFileUploadError('');
    setSelectedFile(null);
    handleModalClick();
    setIsValidFile(false);
    setCorrectInvestorData([]);
    setIncorrectDataList([]);
  };

  const { mutate: validateInvestors, isLoading: isInvestorsValidating } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_NODE_INVESTOR_SERVER_BASE_URL as string,
        query: data.query,
        variables: { investorsId: data.variables },
      });
    },
    {
      onSuccess(response: any) {
        const data = response.data.data['verifyInvestors'];
        const validInvestorDetails = data.validInvestorDetails;
        const invalidInvestorIds = data.invalid;
        const invalidData = acceptedFileData
          .filter((item: any) => invalidInvestorIds.includes(item.investor_id))
          .map((item: any) => ({ ...item, validateInvestorAPI: true }));
        setIncorrectDataList((prevState: any) => 
        [...prevState, ...invalidData.map((data: any, index: number) => { data.client_side_id = +(prevState.length + index + 1); return data }) ]
        );
        
        const localAcceptedData = [...acceptedFileData];
        const updatedFileData = [...localAcceptedData]
          .map((el: any) => {
            const validInvestor = validInvestorDetails.find(
              (validEl: any) => validEl.investor_id_simple === el.investor_id,
            );
            if (validInvestor) {
              return {
                ...el,
                account_name_API: validInvestor.account_name,
                invalidInvestorName: el.account_name !== validInvestor.account_name,
              };
            } else return null;
          })
          .filter((el: any) => el !== null);
        setCorrectInvestorData(updatedFileData);
      },
    },
  );

  const { mutate: validateFunds, isLoading: isFundsValidating } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { fundsId: data.variables },
      });
    },
    {
      onSuccess(response: any) {
        const data = response.data.data['verifyFunds'];
        const validFundDetails = data.validFundDetails;
        const invalidFundIds = data.invalid;
        const invalidData = acceptedFileData
          .filter((item: any) => invalidFundIds.includes(item.fund_id))
          .map((item: any) => ({ ...item, validateFundAPI: true }));
        const localAcceptedData = [...acceptedFileData];
        const updatedFileData = [...localAcceptedData]
          .map((el: any) => {
            const validFund = validFundDetails.find(
              (validEl: any) => validEl.fund_id_simple === el.fund_id,
            );
            if (validFund) {
              return {
                ...el,
                fund_name_API: validFund.fund_name,
                invalidFundName: el.fund_name !== validFund.fund_name,
              };
            } else return null;
          })
          .filter((el: any) => el !== null);
        setIncorrectDataList((prevState: any) => [...prevState, 
          ...invalidData.map((data: any, index: number) => { data.client_side_id = +(prevState.length + index + 1); return data }) ]);
        setCorrectFundData(updatedFileData);
      },
    },
  );

  async function importFile(evt: any) {
    if (evt) {
      var r = new FileReader();
      r.readAsBinaryString(evt);
      r.onload = async (e: any) => {
        if (!e.target.result) {
          setUploadError(true);
          return;
        }
        const json = await processExcel(e.target.result);
        const data = await errorValidation(json);
        setIsValidFile(data.isHeadersValid);
        if (!data.isHeadersValid) {
          setUploadError(true);
        } else {
          setAcceptedFileData(convertDataToStringType(data.validData));
          setIncorrectDataList(
            convertDataToStringType(data.invalidData).map((item: any) => ({
              ...item,
              validateInvestorAPI: false,
              validateFundAPI: false,
            })),
          );
        }
      };
    }
  }

  const mergeCorrectData = () => {
    return correctInvestorData
      .map((el: any) => {
        const item = correctFundData.find((fund: any) => fund.client_side_id === el.client_side_id);
        if (item) {
          return {
            ...el,
            fund_name: item?.fund_name,
            fund_name_API: item?.fund_name_API,
            invalidFundName: item?.invalidFundName,
          };
        } else return null;
      })
      .filter((el: any) => el !== null);
  };

  const generateValidData = () => {
    return mergeCorrectData();
  };

  const generateInvalidData = () => {
    const result: any = [];
    incorrectDataList.forEach((e: any, i: number) => {
      const indexOfExisting = result.findIndex((x: any) => x.client_side_id === e.client_side_id);
      if (indexOfExisting === -1) {
        result.push(e);
      } else {
        // result[indexOfExisting].value.push(e.value);
        e.validateInvestorAPI &&
          (result[indexOfExisting].validateInvestorAPI = e.validateInvestorAPI);
        e.validateFundAPI && (result[indexOfExisting].validateFundAPI = e.validateFundAPI);
      }
    });

    return result;
  };

  useEffect(() => {
    if (acceptedFileData.length > 0) {
      const validFundsIds = acceptedFileData?.map((el: any) => el.fund_id);
      const validInvestorIds = acceptedFileData?.map((el: any) => el.investor_id);
      if (validFundsIds?.length > 0) {
        validateFunds({
          query: VALIDATE_FUNDS,
          variables: validFundsIds,
        });
      }
      if (validInvestorIds?.length > 0) {
        validateInvestors({
          query: VALIDATE_INVESTORS,
          variables: validInvestorIds,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFileData]);

  const onHandleFileDrop = async (fileRejections: any, acceptedFiles: any) => {
    if (fileRejections.length > 0) {
      setFileUploadError(fileRejections[0].file.name);
      setSelectedFile(null);
    } else {
      await closeModal();
      setFileUploadError('');
      setSelectedFile(acceptedFiles[0]);
      await importFile(acceptedFiles[0]);
    }
  };

  return (
    <>
      <UploadHoldingsModal
        isModalOpen={isModalOpen}
        handleModalClick={handleModalClick}
        onFileUpload={onFileUpload}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        closeModal={closeModal}
        onHandleFileDrop={onHandleFileDrop}
        fileUploadError={fileUploadError}
      />
      <PreviewModal
        isLoading={isInvestorsValidating || isFundsValidating}
        isModalOpen={isValidFile}
        selectedFile={selectedFile}
        incorrectDataList={generateInvalidData()}
        acceptedFileData={generateValidData()}
        handleModalClose={closeModal}
      />
    </>
  );
};

export default HoldingsModal;
