import styled from '@emotion/styled';
import MuiModal from 'common/components/modal';
import { useEffect, useState } from 'react';
import WealthBanner from 'common/assets/images/WealthBanner.png';
import MuiButton from 'common/components/button';
import { useAppSelector } from 'store/hooks';
import { selectUserProfile } from 'store/user/selectors';

const StyledModal = styled(MuiModal)`
  .MuiDialog-paper {
    max-width: 834px;
  }
  .MuiDialogTitle-root {
    display: none;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .banner {
    position: relative;
    padding: 4rem 4rem 2.5rem;
    color: white;
    img {
      position: absolute;
      inset: 0;
      z-index: 1;
    }
    h1,
    h4 {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: 2.25rem;
    }
    h4 {
      width: 50ch;
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .content {
    padding: 2.5rem 13.125rem 4rem 4rem;

    p {
      color: #344054;
      margin-bottom: 60px;
    }

    a {
      color: #0b5cd7;
      text-decoration: none;
    }

    button {
      background: #101828;
      &:hover {
        opacity: 0.9;
        background: #101828;
      }
    }
  }
`;

function WealthRedirectModal() {
  const userProfile: any = useAppSelector(selectUserProfile);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userProfile && userProfile?.wealth_advisory_employee_id) setIsModalOpen(true);
    else setIsModalOpen(false);
  }, [userProfile]);

  return (
    <StyledModal
      title='Wealth Redirect'
      maxWidth='sm'
      isModalOpen={isModalOpen}
      handleClose={() => {}}
      zIndex={9999999999}
    >
      <div className='banner'>
        <img src={WealthBanner} alt='' />
        <h1 className='mb-2 font-wt-600'>Tifin Private Markets</h1>
        <h4 className='mb-0 font-wt-500'>
          We have an exciting update to share - TIFIN Private Markets will join our other solutions
          on a single{' '}
          <a href='https://app.tifingrow.com' target='_blank' rel='noreferrer noopener'>
            TIFIN Wealth Platform
          </a>
          .
        </h4>
      </div>
      <div className='content'>
        <p>
          You can now access all of TIFIN's offerings to grow your business, including risk
          tolerance, financial planning, charitable giving, marketing automation, investment
          proposal optimization - and more - all in one place with your Tifin Private Markets login
          credentials. All your client profiles, order and subscription data have moved from Tifin
          Private Markets to the Tifin Wealth Platform.
          <br />
          <br />
          <a href='https://app.tifingrow.com' target='_blank' rel='noreferrer noopener'>
            Log in
          </a>{' '}
          to Tifin Wealth to access our Private Markets portal, and request access to the other
          tools.
        </p>

        <a href='https://app.tifingrow.com' target='_blank' rel='noreferrer noopener'>
          <MuiButton variant='contained'>Take Me to TIFIN Wealth</MuiButton>
        </a>
      </div>
    </StyledModal>
  );
}

export default WealthRedirectModal;
