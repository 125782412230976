import { Container, Divider, Grid, Pagination, PaginationItem } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'common/assets/images/DocDelete.svg';
import MuiButton from 'common/components/button';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Textbox from 'common/components/inputField/Textbox';
import {
  selectFundID,
  selectFundPriceMonthlyReturns,
  selectFundPriceInceptionReturns,
} from 'store/funds/selectors';
import {
  setFundPriceAnnualReturns,
  setFundPriceMonthlyReturns,
  setFundPriceInceptionReturns,
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
} from 'store/funds/slice';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { useMutation, useQueryClient } from 'react-query';
import LoadingButton from 'common/components/button/LoadingButton';
import InceptionReturnsTable from 'components/tables/Investments/HistoricalReturns/InceptionReturnsTable';
import { INCEPTION_RTN_COLUMNS } from './constants';
import { MUTATE_HISTORICAL_RETURNS_QUERY } from './queries';
import { keysToCamel } from './utils';
import { handleGraphqlMutation } from 'helpers';
import HistoricalReturnsModal from 'components/modals/investments/historicalReturns/HistoricalReturnsModal';
import UploadHoldingsError from 'components/modals/holdings/UploadHoldingsError';
import styled from '@emotion/styled';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
};

const StyledWrapper = styled.div`
  .tableContainer {
    background: white;
    border-radius: 1rem;
    border: 1px solid #ddd;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;

    hr {
      border-color: var(--s10);
    }

    .table-head {
      background-color: var(--s10);
      padding: 1rem 2rem;
      h4 {
        color: #525863;
        font-size: 13px;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;
      }
    }
    .table-row {
      h4 {
        color: var(--s40);
        font-size: 13px;
      }
    }

    .MuiPagination-root {
      padding: 1rem 2rem;
    }
  }
`;

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const InvestmentHistoricalReturnsGQL = ({
  handleSubmitBtnClick,
  handleBackBtnClick,
  editing,
}: Props) => {
  const [yearInMonthlyTab, setYearInMonthlyTab] = useState<any>(new Date().getFullYear());

  const [allYearsInMonthlyTab, setAllYearsInMonthlyTab] = useState<any[]>([
    new Date().getFullYear(),
  ]);
  const queryClient = useQueryClient();
  const [monthlyPriceReturns, setMonthlyPriceReturns] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [filteredMonthlyPriceReturns, setFilteredMonthlyPriceReturns] = useState<any[]>([]);
  const [filteredYearIndex, setFilteredYearIndex] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [firstYearReturn, setFirstYearReturn] = useState<string>('');
  const [thirdYearReturn, setThirdYearReturn] = useState<string>('');
  const [fifthYearReturn, setFifthYearReturn] = useState<string>('');
  const [tenthYearReturn, setTenthYearReturn] = useState<string>('');
  const [sinceInceptionReturn, setSinceInceptionReturn] = useState<string>('');

  const navigate = useNavigate();

  const location = useLocation();
  const id = location.pathname.split('/')[3];

  const storeFundMonthlyPriceReturns = useAppSelector(selectFundPriceMonthlyReturns);
  const storeFundInceptionReturns = useAppSelector(selectFundPriceInceptionReturns);
  const storeFundNewID = useAppSelector(selectFundID);
  const dispatch = useTypedDispatch();

  const setDefaultDetails = (details: any) => {
    setFirstYearReturn(details.one_year_return);
    setThirdYearReturn(details.three_years_return);
    setFifthYearReturn(details.five_years_return);
    setTenthYearReturn(details.ten_years_return);
    setSinceInceptionReturn(details.since_inception_return);
  };

  useEffect(() => {
    if (storeFundInceptionReturns) {
      setDefaultDetails(storeFundInceptionReturns);
    }
    // eslint-disable-next-line
  }, [storeFundInceptionReturns]);

  useEffect(() => {
    if (storeFundMonthlyPriceReturns) {
      handleMonthlyPriceReturns(storeFundMonthlyPriceReturns);
    }
    // eslint-disable-next-line
  }, [storeFundMonthlyPriceReturns]);

  const handleMonthlyPriceReturns = (monthlyPriceReturns: any[]) => {
    if (monthlyPriceReturns.length === 0) {
      return;
    }

    const yearsPresent: any[] = [];

    const defaultMonthlyPriceReturns: any[] = [...monthlyPriceReturns];

    const sortedStoreFundMonthlyReturns = defaultMonthlyPriceReturns.sort(
      (a, b) => b.return_year - a.return_year || b.return_month - a.return_month,
    );

    sortedStoreFundMonthlyReturns.forEach((el: any) => {
      if (!yearsPresent.includes(el.return_year)) {
        yearsPresent.push(el.return_year);
      }
    });

    const lastYear = yearsPresent[0];
    setYearInMonthlyTab(lastYear);
    setPage(0);
    setAllYearsInMonthlyTab(yearsPresent);
    setMonthlyPriceReturns(sortedStoreFundMonthlyReturns);

    filterMonthlyPriceReturns(sortedStoreFundMonthlyReturns, lastYear);
  };

  const filterMonthlyPriceReturns = (
    currentMonthlyPriceReturns: any[],
    currentYearInMonthlyReturns?: number,
  ) => {
    const currentYear = currentYearInMonthlyReturns
      ? currentYearInMonthlyReturns
      : yearInMonthlyTab;
    const currentMonthlyReturns = currentMonthlyPriceReturns
      ? currentMonthlyPriceReturns
      : monthlyPriceReturns;

    const filteredMonthlyReturns = currentMonthlyReturns.filter(
      (el) => el.return_year === currentYear,
    );

    if (filteredMonthlyReturns.length === 0) {
      setFilteredMonthlyPriceReturns([]);
      handleMonthlyPriceReturns(currentMonthlyPriceReturns);
      return;
    }

    const requiredIndex = currentMonthlyReturns
      .map(function (e) {
        return e.return_year;
      })
      .indexOf(currentYear);

    setFilteredYearIndex(requiredIndex);
    setFilteredMonthlyPriceReturns(filteredMonthlyReturns);
  };

  const { mutate: mutateFundInceptionReturns, isLoading: mutatingInceptionReturns } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        if (response?.status === 500 || response?.status === 503) {
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'Something went wrong. Please try again.',
          };
          dispatch(showToast(toast));
          return;
        }
        queryClient.invalidateQueries(`getFundDetails-${id ?? storeFundNewID}`, {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(`fundDetailsGQl${id ?? storeFundNewID}`, {
          refetchInactive: true,
        });
        handleSubmitBtnClick();
      },
      onError(error: any) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
        return;
      },
    },
  );

  const handleSaveAndNext = async () => {
    const historicalReturns = {
      one_year_return: firstYearReturn || null,
      three_years_return: thirdYearReturn || null,
      five_years_return: fifthYearReturn || null,
      ten_years_return: tenthYearReturn || null,
      since_inception_return: sinceInceptionReturn || null,
      fundId: id ? id : storeFundNewID,
    };
    const camelCaseInceptionReturns = keysToCamel(historicalReturns);
    dispatch(setFundPriceMonthlyReturns(monthlyPriceReturns));
    mutateFundInceptionReturns({
      query: MUTATE_HISTORICAL_RETURNS_QUERY,
      variables: { ...camelCaseInceptionReturns, monthlyReturns: monthlyPriceReturns },
    });

    dispatch(setFundPriceMonthlyReturns(monthlyPriceReturns));
    dispatch(setFundPriceInceptionReturns(historicalReturns));
  };

  const handleDeleteMonth = (index: number) => {
    const currentMonthlyPriceReturns = [...monthlyPriceReturns];
    currentMonthlyPriceReturns.splice(index, 1);
    setMonthlyPriceReturns(currentMonthlyPriceReturns);
    filterMonthlyPriceReturns(currentMonthlyPriceReturns);
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    dispatch(setFundTermsAndServices(null));
    dispatch(setFundPriceAnnualReturns(null));
    dispatch(setFundPriceMonthlyReturns(null));
    dispatch(setFundPriceInceptionReturns(null));
    navigate(`/investment/${id ?? storeFundNewID}`);
  };

  const handleGoBack = async () => {
    const parsedMonthlyReturns = monthlyPriceReturns.map((item: any) => {
      return {
        ...item,
      };
    });

    await dispatch(setFundPriceMonthlyReturns(parsedMonthlyReturns));

    const returnsSinceInception = {
      one_year_return: firstYearReturn || null,
      three_years_return: thirdYearReturn || null,
      five_years_return: fifthYearReturn || null,
      ten_years_return: tenthYearReturn || null,
      since_inception_return: sinceInceptionReturn || null,
      fundId: id ? id : storeFundNewID,
    };

    dispatch(setFundPriceInceptionReturns(returnsSinceInception));

    handleBackBtnClick();
  };

  const handlePriceInputChange = (value: string, index: number) => {
    let newMonthlyReturns = [...monthlyPriceReturns];
    let newMonthlyReturnObj = { ...monthlyPriceReturns[index] };
    newMonthlyReturnObj.return_value = /^-?[0-9]*\.?[0-9]*$/.test(value)
      ? value
      : newMonthlyReturnObj.return_value;
    newMonthlyReturns[index] = newMonthlyReturnObj;
    setMonthlyPriceReturns(newMonthlyReturns);
    filterMonthlyPriceReturns(newMonthlyReturns);
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/^-?[0-9]*\.?[0-9]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleAddNewMonth = () => {
    const currentMonthlyReturns = [...monthlyPriceReturns];
    const currentLatestMonth = currentMonthlyReturns[0].return_month;
    const currentLatestYear = currentMonthlyReturns[0].return_year;

    if (currentLatestMonth + 1 > new Date().getMonth() + 1) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'You cannot add future dates.',
      };
      dispatch(showToast(toast));
      return;
    }

    if (currentLatestMonth === 12) {
      currentMonthlyReturns.unshift({
        return_month: 1,
        return_year: currentLatestYear + 1,
        return_value: '0.00',
      });
    } else {
      currentMonthlyReturns.unshift({
        return_month: currentLatestMonth + 1,
        return_year: currentLatestYear,
        return_value: '0.00',
      });
    }
    handleMonthlyPriceReturns(currentMonthlyReturns);
  };

  // Sticky ctaContainer handling
  const ctaRef = useRef(null);
  const [ctaSticky, setCtaSticky] = useState<boolean>(false);
  useLayoutEffect(() => {
    const el = ctaRef?.current;
    const observer = new IntersectionObserver(([e]) => setCtaSticky(!e.isIntersecting), {
      threshold: [1],
    });
    if (el) {
      observer.observe(el);
    }

    // Cleanup
    return () => {
      if (el) observer.unobserve(el);
    };
  }, [ctaRef]);

  return (
    <StyledWrapper>
      <Container maxWidth='xl' className='container-lr-padding investmentForm'>
        {/* Historical Returns */}
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <h2>Historical Returns</h2>
            <p>
              Where available, add historical returns here. Leave this section blank for
              Closed-Ended funds without historial returns.
            </p>
          </Grid>
          <Grid item xs={7} container className='flex-center-center'>
            {!filteredMonthlyPriceReturns?.length ? (
              <Grid item xs={12} className='flex-col flex-center-center' pt={5}>
                <p className='mb-3'>You have not added any performance data for this fund.</p>
                <MuiButton
                  buttonClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  Upload Monthly Performance CSV
                </MuiButton>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <div className='flex-center-between mb-3'>
                  <MuiButton className='p-0' buttonClick={() => handleAddNewMonth()}>
                    + Add New Month
                  </MuiButton>
                  <MuiButton
                    className='p-0'
                    buttonClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    Upload Performance CSV
                  </MuiButton>
                </div>
                <div className='tableContainer'>
                  <Grid container columnSpacing={4} className='table-head'>
                    <Grid item xs={6}>
                      <h4>MONTH</h4>
                    </Grid>
                    <Grid item xs={6}>
                      <h4>RETURN</h4>
                    </Grid>
                  </Grid>
                  {filteredMonthlyPriceReturns?.map((el: any, i: number) => (
                    <>
                      <Grid
                        container
                        className='py-3 table-row'
                        key={el}
                        sx={{ px: 4 }}
                        columnSpacing={4}
                        alignItems='center'
                      >
                        <Grid item xs={6} className='flex-center-start'>
                          <h4>{`${monthNames[el.return_month - 1]}-${el.return_year}`}</h4>
                        </Grid>
                        <Grid item xs={6} className='flex-center-between'>
                          <Textbox
                            value={el.return_value.toString()}
                            className='mt-0'
                            type='text'
                            keyDownFunc={handleKeyDownNumber}
                            startAdornment='%'
                            onChange={(e: any) => {
                              handlePriceInputChange(
                                e.target.value.replace(/,/g, ''),
                                i + filteredYearIndex,
                              );
                            }}
                          />
                          <DeleteIcon
                            className='cursor-pointer'
                            onClick={() => handleDeleteMonth(i + filteredYearIndex)}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}
                  <Pagination
                    className='pagination'
                    count={allYearsInMonthlyTab.length}
                    page={page + 1}
                    onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                      setPage(value - 1);
                      setYearInMonthlyTab(allYearsInMonthlyTab[value - 1]);
                      filterMonthlyPriceReturns(
                        monthlyPriceReturns,
                        allYearsInMonthlyTab[value - 1],
                      );
                    }}
                    renderItem={(item) => {
                      return (
                        <>
                          {item.type === 'page' && (
                            <button
                              onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                item.onClick(e);
                              }}
                              className={item.selected ? 'Mui-selected' : ''}
                            >
                              {allYearsInMonthlyTab[item.page - 1]}
                            </button>
                          )}
                          <PaginationItem
                            className={item.type === 'page' ? 'd-none' : ''}
                            {...item}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </Grid>
            )}

            <HistoricalReturnsModal
              isModalOpen={isModalOpen}
              handleModalClick={() => {
                setIsModalOpen(false);
              }}
              uploadError={uploadError}
              setUploadError={setUploadError}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
            />

            <UploadHoldingsError
              isModalOpen={uploadError}
              handleClose={() => {
                setUploadError(false);
                setSelectedFile(null);
              }}
              fileName={selectedFile?.name}
              buttonClick={() => {
                setSelectedFile(null);
                setIsModalOpen(true);
                setUploadError(false);
              }}
            />

            {/* <Grid item xs={12}>
              <ToggleButtonGroup
                color='primary'
                value={toggle}
                exclusive
                fullWidth
                className='monthYearToggle'
                onChange={(e: any) => {
                  setToggle(e.target.value);
                  setOpenDatePicker(false);
                  setOpenMonthlyDatePicker(false);
                }}
              >
                <ToggleButton value='Monthly'>Monthly</ToggleButton>
                <ToggleButton value='Yearly'>Yearly</ToggleButton>
              </ToggleButtonGroup>
            </Grid> */}

            {/* <Grid item xs={12}>
              <Grid item xs={7} className='flex-center-start'>
                <h4 className='sub-heading mb-0'>RATE OF RETURN {toggle === 'Monthly' && 'FOR'}</h4>
                {toggle === 'Monthly' && (
                  <>
                    <Button
                      id='basic-button'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      className='ml-2'
                    >
                      {allYearsInMonthlyTab.length === 0 && 'Add New Year'} {yearInMonthlyTab}{' '}
                      <DownArrow sx={{ fontSize: '1rem' }} />
                    </Button>
                    <Menu
                      className='dropdown-menu'
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem>
                        {toggle === 'Monthly' && (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <StyledDatePicker
                              className='date-picker'
                              views={['year']}
                              inputFormat={'yyyy'}
                              value={yearDateInMonthlyTab}
                              onChange={(date) => handleYearChange(date, 'Monthly')}
                              open={openDatePicker}
                              maxDate={new Date()}
                              PopperProps={{ className: 'styled-date-picker' }}
                              renderInput={(params) => {
                                return (
                                  <div ref={params.inputRef}>
                                    <MuiButton
                                      variant='text'
                                      color='primary'
                                      className='action-btns'
                                      ref={addNewForYear}
                                      buttonClick={() => handleBtnClick('new')}
                                    >
                                      Add New
                                    </MuiButton>
                                  </div>
                                );
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      </MenuItem>
                      {allYearsInMonthlyTab.map((el) => (
                        <MenuItem onClick={(e: any) => handleClose(e)}>
                          {el}{' '}
                          <DeleteIcon
                            className='delete-icon ml-3'
                            onClick={() => handleDeleteYear('Monthly', el)}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </Grid>

              <Grid item xs={5} className='flex-center-end'>
                {!inEditingMode && (
                  <MuiButton
                    variant='outlined'
                    className='action-btns'
                    buttonClick={() => handleBtnClick('edit')}
                  >
                    Edit
                  </MuiButton>
                )}
                {inEditingMode && (
                  <>
                    <MuiButton
                      variant='text'
                      className='action-btns'
                      buttonClick={() => handleBtnClick('cancel')}
                    >
                      Cancel
                    </MuiButton>
                    {toggle === 'Yearly' && (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className='date-picker'
                          views={['year']}
                          inputFormat={'yyyy'}
                          value={yearDateInAnnualyTab}
                          open={openDatePicker}
                          onChange={(date) => handleYearChange(date, 'Yearly')}
                          minDate={new Date('2005/01/01')}
                          maxDate={new Date()}
                          PopperProps={{ className: 'styled-date-picker-yearly' }}
                          renderInput={(params) => (
                            <div ref={params.inputRef}>
                              <MuiButton
                                variant='text'
                                color='primary'
                                className='action-btns'
                                buttonClick={() => handleBtnClick('new')}
                              >
                                Add New
                              </MuiButton>
                            </div>
                          )}
                        />
                      </LocalizationProvider>
                    )}
                    {toggle === 'Monthly' && (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StyledDatePicker
                          className='date-picker'
                          views={['month']}
                          inputFormat={'MM'}
                          value={monthlyDateInMonthlyTab}
                          onChange={(date) => handleAddMonth(date)}
                          open={openMonthlyDatePicker}
                          maxDate={
                            yearInMonthlyTab === new Date().getFullYear() ? new Date() : null
                          }
                          PopperProps={{ className: 'styled-date-picker-monthly' }}
                          renderInput={(params) => (
                            <div ref={params.inputRef}>
                              <MuiButton
                                variant='text'
                                color='primary'
                                className='action-btns'
                                ref={addNewForMonth}
                                buttonClick={() =>
                                  setOpenMonthlyDatePicker((prevValue) => !prevValue)
                                }
                              >
                                Add New Month
                              </MuiButton>
                            </div>
                          )}
                        />
                      </LocalizationProvider>
                    )}
                    <MuiButton
                      variant='contained'
                      className='action-btns'
                      buttonClick={() => handleBtnClick('save')}
                    >
                      Save
                    </MuiButton>
                  </>
                )}
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>

        {/* INCEPTION RETURNS */}
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <h2>Inception returns</h2>
            <p>
              Add returns for specific time periods here. This section is to be updated on a monthly
              basis.
            </p>
          </Grid>
          <Grid item xs={7}>
            <InceptionReturnsTable
              rows={[]}
              columns={INCEPTION_RTN_COLUMNS}
              handleKeyDownNumber={handleKeyDownNumber}
              firstYearReturn={firstYearReturn}
              setFirstYearReturn={setFirstYearReturn}
              thirdYearReturn={thirdYearReturn}
              setThirdYearReturn={setThirdYearReturn}
              fifthYearReturn={fifthYearReturn}
              setFifthYearReturn={setFifthYearReturn}
              tenthYearReturn={tenthYearReturn}
              setTenthYearReturn={setTenthYearReturn}
              sinceInceptionReturn={sinceInceptionReturn}
              setSinceInceptionReturn={setSinceInceptionReturn}
            />
          </Grid>
        </Grid>
      </Container>

      {/* CTAs */}
      <div className={`ctaContainer ${ctaSticky ? 'ctaSticky' : ''}`} ref={ctaRef}>
        <Container maxWidth='xl' className='container-lr-padding flex-center-end'>
          <MuiButton variant='text' buttonClick={handleGoBack}>
            Back
          </MuiButton>
          <MuiButton
            variant='text'
            color='error'
            className='ml-3'
            buttonClick={handleDiscardChanges}
          >
            Exit Without Saving
          </MuiButton>
          {mutatingInceptionReturns ? (
            <LoadingButton minWidth='150px' className='ml-3' />
          ) : (
            <MuiButton
              minWidth='150px'
              className='ml-3'
              variant='contained'
              buttonClick={handleSaveAndNext}
            >
              Save & Next
            </MuiButton>
          )}
        </Container>
      </div>
    </StyledWrapper>
  );
};

export default InvestmentHistoricalReturnsGQL;
