import { Container, Grid, SelectChangeEvent, Skeleton } from '@mui/material';
import MuiButton from 'common/components/button';
import Textbox from 'common/components/inputField/Textbox';
import MuiUpload from 'common/components/inputField/Upload';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  selectFundsDetails,
  selectFundStatus,
  selectFundID,
  selectFundLogo,
  selectFundStructAndStats,
  selectFundsTermsAndServices,
} from 'store/funds/selectors';
import {
  setFundsDetails,
  setFundDocuments,
  setNewFundID,
  setFundStatus as setStoreFundStatus,
  setFundStructAndStats,
  setInvestmentStats,
  setFundTermsAndServices,
  setFundPriceInceptionReturns,
  setFundPriceMonthlyReturns,
  setNewFundLogo,
} from 'store/funds/slice';
import {
  useReactQuery,
  useAppSelector,
  useTypedDispatch,
  useGraphQLQuery,
  useRQMutation,
} from 'store/hooks';
import { ErrorMessage } from 'common/components/errorMessageBox';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { useNavigate, useLocation } from 'react-router-dom';
import MultipleSelectField from 'common/components/inputField/MultipleSelectField';
import ControlledSelectField from 'common/components/inputField/ControlledSelectField';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import RichTextEditor from 'common/components/richTextEditor/RichEditor';
import { encryptDocumentUrl } from 'helpers';
import GroupSelectField from 'common/components/inputField/GroupSelectField';
import statusOptions from './statusOptions.json';
import FundLogoPlaceholder from 'common/assets/images/FundLogoPlaceholder.svg';
import { valueFormatter, regexValidator, handleGraphqlMutation } from 'helpers';
import MuiTooltip from 'common/components/tooltip';
import { FUND_FLOW_MANAGER_ALLOCATION_FLOW } from 'common/constants/tooltipContent';
import { GET_FUND_DETAILS_QUERY, MUTATE_FUND_FIRST_STEP_QUERY } from './queries';
import { useMutation, useQueryClient } from 'react-query';
import LoadingButton from 'common/components/button/LoadingButton';
import { keysToCamel } from './utils';
import { CheckCircle } from '@mui/icons-material';
import Loading from 'common/components/Loading';
import { QueryType } from 'common/types';

type Props = {
  handleSubmitBtnClick: any;
  editing?: boolean;
  status?: string;
};

const InvestmentDetialsForm = ({ handleSubmitBtnClick, editing, status }: Props) => {
  const [selectedAssetClass, setSelectedAssetClass] = useState<any>('');
  const [selectedAssetClassArr, setSelectedAssetClassArr] = useState<string[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<any>('');
  const [selectedThemeArr, setSelectedThemeArr] = useState<string[]>([]);
  const [logoName, setLogoName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<any>(null);
  const logoImage = useRef<HTMLImageElement>(null);
  const [strategyDescription, setStrategyDescription] = useState(() => EditorState.createEmpty());
  const [strategyDescriptionText, setStrategyDescriptionText] = useState<string>('');
  const [managersBio, setManagersBio] = useState(() => EditorState.createEmpty());
  const [managersBioText, setManagersBioText] = useState<string>('');
  // error states
  const [strategyDescriptionError, setStrategyDescriptionError] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);
  const [fund, setFundStatus] = useState<string>('');
  const [minInvestment, setMinInvestment] = useState<any>('');
  const queryClient = useQueryClient();

  const [documentData, setDocumentData] = useState<any>({
    doc_name: 'logo.jpeg',
    doc_type: 'logo',
    doc_config_type_id: null,
    doc_category_id: '4bc73402-852e-452a-91fc-cd405d3d48e3',
    doc_classification_name: null,
    doc_category_type_id: 1,
    status: 'Active',
    doc_config_id: null,
    object_key: '4bc73402-852e-452a-91fc-cd405d3d48e3/logo/logo.jpeg',
    document_type: 'FUND',
  });
  const navigate = useNavigate();

  const newFundId = useAppSelector(selectFundID);
  const newFundLogo = useAppSelector(selectFundLogo);
  const storeFundDetails = useAppSelector(selectFundsDetails);
  const fundStatusFromStore = useAppSelector(selectFundStatus);
  const storeFundStructAndStats = useAppSelector(selectFundStructAndStats);
  const storeFundTSP = useAppSelector(selectFundsTermsAndServices);

  const dispatch = useTypedDispatch();
  const [fundStatusError, setFundStatusError] = useState<boolean>(false);

  // Edit Funds state
  const location = useLocation();
  const id = location.pathname.split('/')[3];

  const { data: fundDetailsGQL, isLoading } = useGraphQLQuery(
    `fundDetailsGQl${id}`,
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUND_DETAILS_QUERY,
      variables: {
        fundId: id,
      },
    },
    { enabled: editing, refetchOnMount: true },
  );

  const { data: documentsData } = useReactQuery(
    [`documents-${id}`],
    {
      url: `qaip/v1/documentmanagement/getList/fund?fund_id=${id}&document_type=file`,
    },
    {
      enabled: editing,
      refetchOnMount: true,
    },
  );

  const fundDetails = fundDetailsGQL?.getFundDetails?.fundDetails;
  const fundManagerTrackRecords = fundDetailsGQL?.getFundDetails?.fundStats;
  const monthlyReturns = fundDetailsGQL?.getFundDetails.monthlyReturns;
  const isAllocationWorkflowEnabled =
    fundDetails?.isAllocationWorkflowEnabled === 'no' ? false : true;

  useEffect(() => {
    if (documentsData) {
      setFundDocumentsData();
    }
    // eslint-disable-next-line
  }, [documentsData]);

  useEffect(() => {
    const stratText = strategyDescription.getCurrentContent().getPlainText();
    setStrategyDescriptionText(stratText);
  }, [strategyDescription]);

  const setFundDocumentsData = () => {
    dispatch(setFundDocuments(documentsData));
    dispatch(setNewFundID(id));
  };

  useEffect(() => {
    const newManagerBioText = managersBio.getCurrentContent().getPlainText();
    setManagersBioText(newManagerBioText);
  }, [managersBio]);

  //* THIS IS THE USE-EFFECT TO SETUP INFORMARION FOR 1ST STEP FROM GQL BACKEND
  useEffect(() => {
    if (fundDetailsGQL) {
      setSelectedAssetClass(fundDetails?.asset_class);
      setSelectedAssetClassArr(fundDetails && fundDetails['asset_class']?.split(','));
      setSelectedTheme(fundDetails?.fund_themes);
      setStrategyDescription(
        EditorState.createWithContent(stateFromHTML(fundDetails?.strategy_description)),
      );
      setFundStatus(fundDetails?.status);
      fundDetails &&
        fundDetails['fund_themes'] &&
        setSelectedThemeArr(fundDetails['fund_themes']?.split(','));
      const defaultLogoName: string = fundDetails?.logo;
      const defaultLogoNameArr: string[] = defaultLogoName.split('/');
      const editedLogoName: string = defaultLogoNameArr[defaultLogoNameArr.length - 1];
      setLogoName(editedLogoName);
      reset(fundDetails);
      fundDetails?.add_to_all_dg
        ? setValue('fund_subscription', 'Publish to all advisors on platform')
        : setValue('fund_subscription', 'My Advisors Only');
      setManagersBio(
        EditorState.createWithContent(
          stateFromHTML(fundDetails?.managers_bio === null ? '' : fundDetails?.managers_bio),
        ),
      );

      setMinInvestment(fundDetails?.min_investment);

      // Invesrment struct and stats form
      setStoreFundStructAndStats();

      // Investment Terms and services form
      setStoreFundTSP();

      // Investment historical returns form
      setInvestmentHistoricalReturns();

      mutate({ object_key: fundDetails?.logo });
    }
    // eslint-disable-next-line
  }, [fundDetailsGQL]);

  const setStoreFundStructAndStats = () => {
    if (storeFundStructAndStats === null) {
      const newStoreFundStructAndStats = {
        liquidity_for_summary: fundDetails.liquidity_for_summary,
        lockup_for_summary: fundDetails.lockup_for_summary,
        funding_type: fundDetails.funding_type,
        income_distribution: fundDetails.income_distribution,
        funds_transfer_due_date: fundDetails.funds_transfer_due_date,
        fund_assets: fundDetails.fund_assets,
        firm_assets: fundDetails.firm_assets,
        final_close: fundDetails.final_close,
        management_fees: fundDetails.management_fees,
        preferred_return: fundDetails.preferred_return,
        gp_catch_up_term: fundDetails.gp_catch_up_term,
        incentives_fees: fundDetails.incentives_fees,
        investment_period: fundDetails.investment_period,
        fund_term: fundDetails.fund_term,
        redemptions: fundDetails.redemptions,
        lockup: fundDetails.lockup,
        compounded_rate_of_return: fundDetails.compounded_rate_of_return,
        standard_deviation: fundDetails.standard_deviation,
        sharpe_ratio: fundDetails.sharpe_ratio,
        profitable_months_percentage: fundDetails.profitable_months_percentage,
        maximum_drawdown: fundDetails.maximum_drawdown,
        next_close: fundDetails.next_close,
        s_and_p500: fundDetails.s_and_p_500,
        target_fund_size: fundDetails.target_fund_size,
        cash_yield: fundDetails.cash_yield,
        target_irr: fundDetails.target_irr,
        impact_score: fundDetails.impact_score,
      };

      dispatch(setInvestmentStats(fundManagerTrackRecords ? fundManagerTrackRecords : [{}]));
      dispatch(setFundStructAndStats(newStoreFundStructAndStats));
    }
  };

  const setStoreFundTSP = () => {
    if (storeFundTSP === null) {
      const newStoreFundTSP = {
        auditor: fundDetails.auditor,
        administrator: fundDetails.administrator,
        fund_counsel: fundDetails.fund_counsel,
        prime_broker: fundDetails.prime_broker,
        disclaimer: fundDetails.disclaimer,
        bank_details: fundDetails.bank_details,
      };

      dispatch(setFundTermsAndServices(newStoreFundTSP));
    }
  };

  const setInvestmentHistoricalReturns = () => {
    const inceptionReturns = {
      one_year_return: fundDetails.one_year_return || '',
      three_years_return: fundDetails.three_years_return || '',
      five_years_return: fundDetails.five_years_return || '',
      ten_years_return: fundDetails.ten_years_return || '',
      since_inception_return: fundDetails.since_inception_return || '',
    };

    dispatch(setFundPriceMonthlyReturns(monthlyReturns));
    dispatch(setFundPriceInceptionReturns(inceptionReturns));
  };

  //* THIS IS THE USE=EFFECT TO SETUP INFORMARION FROM REDUX
  useEffect(() => {
    if (storeFundDetails) {
      setLogoName(storeFundDetails.logo);
      setSelectedAssetClass(storeFundDetails.asset_class);
      setSelectedAssetClassArr(storeFundDetails['asset_class']?.split(','));
      setSelectedTheme(storeFundDetails.fund_themes);
      setSelectedThemeArr(storeFundDetails['fund_themes']?.split(','));
      setStrategyDescription(
        EditorState.createWithContent(stateFromHTML(storeFundDetails.strategy_description)),
      );
      !editing && setLogoName(storeFundDetails.logo_file.name);

      setManagersBio(EditorState.createWithContent(stateFromHTML(storeFundDetails?.managers_bio)));

      reset(storeFundDetails);

      setMinInvestment(storeFundDetails.min_investment);

      if (!editing && logoImage.current) {
        logoImage.current.src = URL.createObjectURL(storeFundDetails.logo_file);
        setLogoFile(storeFundDetails.logo_file);
      }
    }
    // eslint-disable-next-line
  }, [storeFundDetails]);

  useEffect(() => {
    if (newFundLogo) {
      mutate(newFundLogo);
    }
    //eslint-disable-next-line
  }, [newFundLogo]);

  useEffect(() => {
    if (fundStatusFromStore) {
      setFundStatus(fundStatusFromStore);
      return;
    }
    if (fundDetails) {
      let fundStatusToBeUsed =
        fundDetails?.status === 'Active'
          ? `Active,${fundDetails?.fund_status}`
          : fundDetails?.status === 'InActive'
          ? 'InActive'
          : 'Onboarding - Awaiting Approval';
      setFundStatus(fundStatusToBeUsed);
    }
  }, [fundDetails, fundStatusFromStore, editing]);

  const { data: organisationsList } = useReactQuery(['organisationsList'], {
    url: 'qaip/v1/usermanagement/organisations?categoryId=2',
  });

  const { data: masterData } = useReactQuery(['masterData'], {
    url: 'qaip/v1/fundsmanagement/funds/masterData',
  });

  const {
    mutate,
    data: logo,
    isLoading: logoLoading,
  } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    },
    {
      enabled: false,
    },
  );

  const handleOnFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogoError(false);
    const uploadedFile: any = e.target.files;
    if (logoImage.current) {
      logoImage.current.src = URL.createObjectURL(uploadedFile[0]);
    }
    setLogoFile(uploadedFile[0]);
    const uploadedFileName: string = uploadedFile.item(0).name;
    setLogoName(uploadedFileName);
    e.target.files = null;
    e.target.value = '';
  };

  const handleChangeManagersBio = (newEditorState: any) => {
    setManagersBio(newEditorState);
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    if (editing || newFundId) {
      navigate(`/investment/${id ?? newFundId}`);
    } else {
      navigate(`/investments`);
    }
  };

  const handleChangeStrategyDescription = (newEditorState: any) => {
    setStrategyDescription(newEditorState);
  };

  const handleCheckStatusError = () => {
    const fundstatus = fund.replace('Active,', '');
    if (fund === '' || fundstatus === '' || fundstatus === 'null') {
      setFundStatusError(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (fund) {
      const fundstatus = fund.replace('Active,', '');
      if (fund === '' || fundstatus === '' || fundstatus === 'null') {
        setFundStatusError(true);
      } else {
        setFundStatusError(false);
      }
    }
  }, [fund]);

  const { mutate: updateDocumentData, isLoading: isUploadingDoc } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      isFormData: true,
    },
    {
      onSuccess: () => {
        handleSubmitBtnClick();
      },
    },
  );

  const { mutate: fetchPresignedUrl, isLoading: isFetchingPredesignUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      enabled: false,
      onSuccess: async (data: any) => {
        logoFile && (await encryptDocumentUrl(data.url, logoFile));
        logoFile && updateDocumentData({ documentData });
        setFundStatusRequest({});
        handleSubmitBtnClick();
      },
    },
  );

  const { mutate: mutateFundFirstStep, isLoading: mutatingFund } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        if (response?.status === 500 || response?.status === 503) {
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'Something went wrong. Please try again.',
          };
          dispatch(showToast(toast));
          return;
        }
        queryClient.invalidateQueries(`getFundDetails-${id ?? newFundId}`, {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(`manageFunds`, {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(`fundDetailsGQl${id ?? newFundId}`, {
          refetchInactive: true,
        });
        const newFundData = response.data.data.addNewFunds;
        dispatch(setNewFundID(newFundData.fund_id));

        const objectKey = {
          object_key: !logoFile ? logoName : `${newFundData['fund_id']}/logo/${logoName}`,
        };
        dispatch(setNewFundLogo(objectKey));
        const newDocumentData = {
          ...documentData,
          doc_name: logoName,
          doc_category_id: newFundData['fund_id'],
          object_key: objectKey.object_key,
        };
        setDocumentData(newDocumentData);
        fetchPresignedUrl(objectKey);
      },
      onError(error: any) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
        return;
      },
    },
  );

  const checkFundStatus = (fundStatus: any) => {
    if (fundStatus === 'Inactive') {
      return 'InActive';
    } else if (fundStatus === 'Onboarding Awaiting Apporval') {
      return 'Onboarding - Awaiting Approval';
    } else {
      return fundStatus;
    }
  };
  const { mutate: setFundStatusRequest } = useRQMutation(
    {
      url: `/qaip/v1/fundsmanagement/fund/id/${id ?? newFundId}?status=${checkFundStatus(
        fundStatusFromStore,
      )}`,
      method: QueryType.PUT,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`getFundDetails-${id ?? newFundId}`);
      },
    },
  );

  const onSubmit: SubmitHandler<any> = async (data) => {
    const isValid = handleCheckStatusError();
    if (!isValid) {
      window.scrollTo(0, 0);
      return;
    }
    if (strategyDescriptionText.length === 0) {
      setStrategyDescriptionError(true);
      return;
    }

    if (!editing && logoName === '') {
      setLogoError(true);
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Please upload the logo.',
      };
      dispatch(showToast(toast));
      window.scrollTo(0, 0);
      return;
    }

    const { fund_subscription, logo_file, ...restData } = data;

    const newFundDetails = {
      ...restData,
      asset_class: selectedAssetClass,
      fund_themes: selectedTheme,
      logo: logoName,
      fund_id: id ? id : newFundId ? newFundId : '',
      strategy_description:
        strategyDescriptionText.length > 0
          ? `${stateToHTML(strategyDescription.getCurrentContent())}`
          : '',
      managers_bio:
        managersBioText.length > 0 ? `${stateToHTML(managersBio.getCurrentContent())}` : '',
      min_investment: minInvestment,
      add_to_all_dg: fund_subscription === 'Publish to all advisors on platform',
    };

    Object.keys(newFundDetails).forEach(
      (key) =>
        (newFundDetails[key] === undefined || newFundDetails[key] === null) &&
        delete newFundDetails[key],
    );
    const camelCaseFundDetails = keysToCamel(newFundDetails);
    dispatch(setFundsDetails({ ...newFundDetails, fund_subscription, logo_file: logoFile }));
    mutateFundFirstStep({
      query: MUTATE_FUND_FIRST_STEP_QUERY,
      variables: camelCaseFundDetails,
    });
  };

  const handleChangeMultipleSelect = (event: SelectChangeEvent, name: string) => {
    const {
      target: { value },
    } = event;

    if (name === 'asset_class') {
      setSelectedAssetClassArr(value as any);
      setSelectedAssetClass((value as any).join(','));
    } else {
      setSelectedThemeArr(value as any);
      setSelectedTheme((value as any).join(','));
    }
  };

  const handleKeyDownOnlyNumbers = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyDownOnlyText = (e: any) => {
    if (!/^[a-zA-Z ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleOptionsSort = (options: any[]) => {
    const editedOptions = options.map((el: any) => ({
      id: el.organisationId,
      title: el.organisationName,
    }));
    const sortedEditedOptions = editedOptions.sort((a: any, b: any) =>
      a.title.localeCompare(b.title),
    );
    return sortedEditedOptions;
  };

  const URLRegex =
    // eslint-disable-next-line
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Sticky ctaContainer handling
  const ctaRef = useRef(null);
  const [ctaSticky, setCtaSticky] = useState<boolean>(false);
  useLayoutEffect(() => {
    const el = ctaRef?.current;
    const observer = new IntersectionObserver(([e]) => setCtaSticky(!e.isIntersecting), {
      threshold: [1],
    });
    if (el) {
      observer.observe(el);
    }

    // Cleanup
    return () => {
      if (el) observer.unobserve(el);
    };
  }, [ctaRef]);

  if (isLoading) return <Loading />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container maxWidth='xl' className='container-lr-padding investmentForm'>
        {/* Fund Manager Details */}
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={4}>
            <h2 className='sub-heading'>Fund Manager Details</h2>
            <p>
              Select Fund Manager (if already created) or{' '}
              <span
                className='cursor-pointer color-p300'
                onClick={() => navigate('/admin/organizations')}
              >
                click here
              </span>{' '}
              to create Fund Manager entity first.
            </p>
          </Grid>
          <Grid item xs={7} container rowSpacing={4}>
            <Grid item xs={12} container>
              <Grid item xs={9} className='flex-col flex-start-between'>
                <h4>
                  Fund Logo Preview<span className='requiredStar'>*</span>
                </h4>
                <div className='flex-center-start logoUpload'>
                  <MuiUpload variant='outlined' onFileUpload={handleOnFileUpload} accept='image/*'>
                    {logoName === '' ? 'Upload' : 'Re-upload'}
                  </MuiUpload>
                  <h5 className='logoFile flex-center-start mb-0'>
                    {logoName && <CheckCircle />}
                    {logoName?.length > 50
                      ? logoName.substring(0, 45) +
                        '...' +
                        logoName.substring(logoName.length - 8, logoName.length)
                      : logoName}
                  </h5>
                </div>
                {logoError && <ErrorMessage error='Please upload the logo' />}
              </Grid>
              <Grid item xs={3} className='flex-center-end'>
                <div className='logoPreview'>
                  {logoLoading ? (
                    <Skeleton variant='rectangular' sx={{ height: '100%' }} />
                  ) : (
                    <img src={logo?.url || FundLogoPlaceholder} alt='logo' ref={logoImage} />
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h4>
                Funds Manager<span className='requiredStar'>*</span>
              </h4>
              <ControlledSelectField
                options={organisationsList && handleOptionsSort(organisationsList)}
                required
                defaultValue={storeFundDetails && storeFundDetails?.fund_manager_id}
                name='fund_manager_id'
                control={control}
                errorMessage='This field is required'
              />
              {errors.fund_manager_id && <ErrorMessage error='This field is required' />}
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>
                  Manager Name<span className='requiredStar'>*</span>
                </h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='fund_manager_contact'
                  errorMessage='This field is required'
                  required={true}
                  keyDownFunc={handleKeyDownOnlyText}
                />
                {errors.fund_manager_contact && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4>
                  Manager's Phone Number<span className='requiredStar'>*</span>
                </h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='fund_manager_phone'
                  maxLength={11}
                  minLength={11}
                  errorMessage='This field is required'
                  startAdornment={'+'}
                  keyDownFunc={handleKeyDownOnlyNumbers}
                />
                {errors.fund_manager_phone && <ErrorMessage error='Enter a valid phone number' />}
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>
                  Manager's Email<span className='requiredStar'>*</span>
                </h4>
                <Textbox
                  fullWidth
                  type='email'
                  register={register}
                  name='fund_manager_email'
                  errorMessage='This field is required'
                  pattern={{
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  }}
                />
                {errors.fund_manager_email?.message && (
                  <ErrorMessage error='Please enter valid email' />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h4>Manager Bio</h4>
              <RichTextEditor
                editorState={managersBio}
                handleChangeEditorState={(newEditorState: any) =>
                  handleChangeManagersBio(newEditorState)
                }
              />
              {managersBioText.length > 5000 && (
                <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Fund Details */}
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <h2>Fund Details</h2>
            <p>Add fund details. Required fields are marked with *</p>
          </Grid>
          <Grid item xs={7} container rowSpacing={4}>
            <Grid item xs={12}>
              <h4>
                Fund Name<span className='requiredStar'>*</span>
              </h4>
              <Textbox
                fullWidth
                required
                register={register}
                name='fund_name'
                errorMessage='This field is required'
              />
              {errors.fund_name && <ErrorMessage error='This field is required' />}
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>
                  Asset Class<span className='requiredStar'>*</span>
                </h4>
                <MultipleSelectField
                  register={register}
                  required={selectedAssetClassArr.length === 0 ? true : false}
                  name='asset_class'
                  options={masterData?.asset_class}
                  onChange={(e: any) => {
                    handleChangeMultipleSelect(e, 'asset_class');
                  }}
                  value={selectedAssetClassArr ?? []}
                  valueArr={selectedAssetClassArr}
                />
                {errors.asset_class && !selectedAssetClass && (
                  <ErrorMessage error='This field is required' />
                )}
              </Grid>
              <Grid item xs={6}>
                <h4>
                  Class<span className='requiredStar'>*</span>
                </h4>
                <ControlledSelectField
                  control={control}
                  options={masterData?.class}
                  defaultValue={storeFundDetails && storeFundDetails?.class}
                  required
                  name='class'
                  searchIcon={false}
                  errorMessage='This field is required'
                />
                {errors.class && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>
                  Fund Type<span className='requiredStar'>*</span>
                </h4>
                <ControlledSelectField
                  control={control}
                  required
                  options={['Evergreen', 'Closed-Ended']}
                  defaultValue={storeFundDetails && storeFundDetails?.fund_type}
                  name='fund_type'
                  searchIcon={false}
                  errorMessage='This field is required'
                />
                {errors.fund_type && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4>
                  Status<span className='requiredStar'>*</span>
                </h4>
                <GroupSelectField
                  searchIcon={false}
                  setSelectedValue={async (val: any) => {
                    await setFundStatus(val);
                  }}
                  value={fund}
                  defaultValue={fund}
                  required
                  name='fund_status'
                  statusOptions={statusOptions}
                  className={`groupSelect ${isAllocationWorkflowEnabled ? 'allocReq' : ''}`}
                  errorMessage='This field is required'
                />
                {fundStatusError && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>
                  Eligibility<span className='requiredStar'>*</span>
                </h4>
                <ControlledSelectField
                  control={control}
                  required
                  defaultValue={storeFundDetails && storeFundDetails?.eligibility}
                  options={masterData?.eligibility}
                  name='eligibility'
                  errorMessage='This field is required'
                  searchIcon={false}
                />
                {errors.eligibility && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4>
                  Themes<span className='requiredStar'>*</span>
                </h4>
                <MultipleSelectField
                  register={register}
                  required={selectedThemeArr.length === 0 ? true : false}
                  options={masterData?.fundThemes}
                  name='fund_themes'
                  onChange={(e: any) => {
                    handleChangeMultipleSelect(e, 'fund_themes');
                  }}
                  value={selectedThemeArr ?? []}
                  searchIcon={false}
                  valueArr={selectedThemeArr}
                />
                {errors.fund_themes && selectedThemeArr.length === 0 && (
                  <ErrorMessage error='This field is required' />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Currency</h4>
                <ControlledSelectField
                  control={control}
                  defaultValue={storeFundDetails && storeFundDetails?.iso_currency}
                  options={masterData?.isoCurrency}
                  name='iso_currency'
                  searchIcon={false}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>
                  Strategy<span className='requiredStar'>*</span>
                </h4>
                <ControlledSelectField
                  control={control}
                  required
                  options={masterData?.strategy}
                  defaultValue={storeFundDetails && storeFundDetails?.strategy}
                  name='strategy'
                  errorMessage='This field is required'
                  searchIcon={false}
                />

                {errors.strategy && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>
                  Sub Strategy<span className='requiredStar'>*</span>
                </h4>
                <Textbox
                  fullWidth
                  required={true}
                  register={register}
                  name='sub_strategy'
                  errorMessage='This field is required'
                />
                {errors.sub_strategy && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4>
                  Minimum Investment<span className='requiredStar'>*</span>
                </h4>
                <Textbox
                  fullWidth
                  register={register}
                  required
                  name='min_investment'
                  errorMessage='This field is required'
                  startAdornment={'$'}
                  value={minInvestment ? valueFormatter(minInvestment) : ''}
                  onChange={(e: any) => {
                    if (regexValidator(e.target.value.replace(/,/g, ''), /^[0-9,]*$/i)) return;
                    setMinInvestment(e.target.value.replace(/,/g, ''));
                  }}
                  keyDownFunc={handleKeyDownOnlyNumbers}
                />
                {errors.min_investment && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              {/* <Grid item xs={6}>
              <h4>
                Rating<span className='requiredStar'>*</span>
              </h4>
              <ControlledSelectField
                control={control}
                options={masterData?.rating}
                name='rating'
                defaultValue={storeFundDetails && storeFundDetails?.rating}
                errorMessage='This field is required'
                searchIcon={false}
              />
              {errors.rating && <ErrorMessage error='This field is required' />}
            </Grid> */}
              <Grid item xs={12}>
                <h4>Fund Video Link</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='fund_video'
                  pattern={{
                    value: URLRegex,
                    message: 'Please enter a valid URL',
                  }}
                />
                {errors.fund_video?.message && <ErrorMessage error='Please enter valid URL' />}
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>
                  Who Can Subscribe To This Fund?<span className='requiredStar'>*</span>
                </h4>
                <ControlledSelectField
                  control={control}
                  options={['My Advisors Only', 'Publish to all advisors on platform']}
                  name='fund_subscription'
                  searchIcon={false}
                  disabled={id || newFundId}
                  errorMessage='This field is required'
                  defaultValue={storeFundDetails && storeFundDetails?.fund_subscription}
                  required
                  placeholder='Select One'
                />
                {errors.fund_subscription && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='reducedFontSize flex-center-between'>
                  <div>
                    Will Fund Manager Assign Allocations For This Fund
                    <span className='requiredStar'>*</span>
                  </div>
                  <MuiTooltip content={FUND_FLOW_MANAGER_ALLOCATION_FLOW} />
                </h4>
                <ControlledSelectField
                  control={control}
                  options={
                    editing
                      ? [
                          { id: 'Yes', title: 'Yes' },
                          { id: 'No', title: 'No' },
                          { id: 'yes', title: 'Yes' },
                          { id: 'no', title: 'No' },
                        ]
                      : ['Yes', 'No']
                  }
                  disabled={id || newFundId}
                  required
                  placeholder='Select One'
                  name='is_allocation_workflow_enabled'
                  errorMessage='This field is required'
                  searchIcon={false}
                  defaultValue={
                    storeFundDetails && storeFundDetails?.is_allocation_workflow_enabled
                  }
                />

                {errors.is_allocation_workflow_enabled && (
                  <ErrorMessage error='This field is required' />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h4>
                Strategy Description<span className='requiredStar'>*</span>
              </h4>
              <RichTextEditor
                editorState={strategyDescription}
                handleChangeEditorState={(newEditorState: any) => {
                  setStrategyDescriptionError(strategyDescriptionText.length === 0);
                  handleChangeStrategyDescription(newEditorState);
                }}
              />
              {strategyDescriptionError && <ErrorMessage error='This field is required' />}
              {strategyDescriptionText.length > 5000 && (
                <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
              )}
            </Grid>
            <Grid item xs={12}>
              <h4>
                Short Description (400 char)<span className='requiredStar'>*</span>
              </h4>
              <Textbox
                fullWidth
                multiline
                rows={4}
                register={register}
                required={true}
                name='fund_tag_line'
                errorMessage='This field is required'
                maxLength={400}
              />
              {errors.fund_tag_line && errors.fund_tag_line.type === 'required' && (
                <ErrorMessage error='This field is required' />
              )}
              {errors.fund_tag_line && errors.fund_tag_line.type === 'maxLength' && (
                <ErrorMessage error='Input limit exceeded. Character Limit is 400' />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* CTAs */}
      <div className={`ctaContainer ${ctaSticky ? 'ctaSticky' : ''}`} ref={ctaRef}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid container>
            <Grid item xs={5} className='flex-center-start'>
              <h4>
                <span className='requiredStar'>*</span>Mandatory Fields
              </h4>
            </Grid>
            <Grid item xs={3} className='flex-center-start'>
              {Object.keys(errors).length > 0 && (
                <ErrorMessage error={'You have missed mandatory fields!'} />
              )}
            </Grid>
            <Grid item xs={4} className='flex-center-end'>
              <MuiButton variant='text' buttonClick={handleDiscardChanges}>
                Back
              </MuiButton>
              <MuiButton
                variant='text'
                color='error'
                className='ml-3'
                buttonClick={handleDiscardChanges}
              >
                Exit Without Saving
              </MuiButton>
              {mutatingFund || isFetchingPredesignUrl || isUploadingDoc ? (
                <LoadingButton minWidth='150px' className='ml-3' />
              ) : (
                <MuiButton
                  minWidth='150px'
                  variant='contained'
                  type='submit'
                  className='ml-3'
                  buttonClick={() => {
                    handleCheckStatusError();
                    dispatch(setStoreFundStatus(fund as string));
                    if (strategyDescriptionText.length === 0) setStrategyDescriptionError(true);
                  }}
                >
                  Save & Next
                </MuiButton>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </form>
  );
};

export default InvestmentDetialsForm;
