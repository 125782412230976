import { FundDetails } from 'common/types';
import CardCarousel from 'common/components/carousel/CardCarousel';
import Engine1Card from './Engine1Card';
import Engine2Card from './Engine2Card';
import Engine4Card from './Engine4Card';

interface Props {
  clientList: any;
  fund: FundDetails;
  className?: string;
  heading: string;
  tooltip?: string | React.ReactNode;
  latestFund: Array<any> | null;
}

const Recommendations = ({
  fund,
  className = 'container-lr-padding',
  heading,
  tooltip = '',
  clientList,
  latestFund,
}: Props) => {
  return (
    <CardCarousel
      ctaText='Get More Recommendations'
      navLink='/recommendations'
      length={4}
      heading={heading}
      className={className}
      tooltip={tooltip}
      compare={!!latestFund}
      latestFund={latestFund}
    >
      {latestFund && <Engine4Card clientList={clientList} startIndex={0} latestFund={latestFund} />}
      {clientList[1] && <Engine2Card clientList={clientList} startIndex={1} />}
      {clientList[2] && (
        <Engine1Card
          clientList={clientList}
          startIndex={2}
          stats={{ portfolioSize: '$15.5M', alloc: '19.50%' }}
        />
      )}
      {clientList[3] && (
        <Engine2Card
          clientList={clientList}
          startIndex={3}
          stats={{
            esg: '18%',
            income: '15%',
            growth: '35%',
            div: '32%',
          }}
        />
      )}
    </CardCarousel>
  );
};

export default Recommendations;
