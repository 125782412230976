import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import LoadingButton from 'common/components/button/LoadingButton';
import SubHeader from 'common/components/layout/SubHeader';
import { useEffect, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useGraphQLQuery, useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { COLUMNS, CRUMBS } from './constants';
import SubMasterListTable from '../../tables/SubscriptionMasterChecklist/SubMasterListTable';
import { handleGraphqlMutation } from 'helpers';

const StyledGrid = styled(Grid)`
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: var(--s30);
  }
`;

const GET_MASTER_CHECKLIST = `
query GetMasterChecklist($payload: MasterChecklistRequest) {
  getMasterChecklist(payload: $payload) {
    id
    created_at
    last_updated_at
    status
    title
    tooltip
    type
    visible_to_entities
    visible_to_hybrids
    visible_to_natural_persons
    fund_id
  }
}
`;

const UPDATE_MASTER_CHECKLIST = `
mutation CreateUpdateMasterChecklist($payload: [MasterChecklist]) {
  createUpdateMasterChecklist(payload: $payload) {
    message
    id
  }
}
`;

const DELETE_MASTER_CHECKLIST = `
mutation DeleteMasterChecklist($deleteMasterChecklistId: Int!) {
  deleteMasterChecklist(id: $deleteMasterChecklistId)
}
`;

const SubscriptionMasterChecklist = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [rows, setRows] = useState<any>([]);
  const [newRows, setNewRows] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {
    data: { getMasterChecklist: masterChecklist } = {},
    isLoading: isFetchingMasterChecklist,
    isRefetching,
  } = useGraphQLQuery(
    ['masterSubscriptionChecklist'],
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_MASTER_CHECKLIST,
      variables: {
        payload: {
          fundId: null,
        },
      },
    },
    { refetchOnWindowFocus: false },
  );

  const { mutate: mutateMasterChecklist, isLoading: mutatingChecklist } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries('masterSubscriptionChecklist');
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Changes have been saved.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const { mutate: deleteMasterChecklist, isLoading: deletingChecklist } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries('masterSubscriptionChecklist');
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Item deleted Successfully',
        };
        dispatch(showToast(toast));
      },
    },
  );

  useEffect(() => {
    if (masterChecklist && masterChecklist.length > 0) {
      const arr = masterChecklist
        .filter((row: any) => !row.fundId)
        .map((row: any, i: number) => ({
          ...row,
          UID: `${i}-${new Date().getTime()}`,
        }));
      setRows([...arr, ...newRows]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterChecklist?.length]);

  const handleUpdateRows = (row: any, index: number) => {
    const arr = [...rows];
    if (!row.isNewRow) {
      row = { ...row, isUpdate: true };
    }
    arr[index] = row;
    setRows(arr);
  };

  const handleDeleteRow = (data: any) => {
    if (data.deleteFromServer) {
      deleteMasterChecklist({
        query: DELETE_MASTER_CHECKLIST,
        variables: {
          deleteMasterChecklistId: data.id,
        },
      });
    } else {
      setRows(rows.filter((row: any) => row.UID !== data.id));
    }
  };

  const handleNullCheck = (rows: any) => {
    const result = rows.some(
      (o: any) =>
        o.title === '' ||
        o.type === '' ||
        o.visible_to_entities === '' ||
        o.visible_to_hybrids === '' ||
        o.visible_to_natural_persons === '',
    );
    return result;
  };

  useEffect(() => {
    setCount(count || rows.length);
    setNewRows(rows.filter((row: any) => !row?.id));
    setIsDisabled(handleNullCheck(rows));
    //eslint-disable-next-line
  }, [rows]);

  const handleUpdateMasterChecklist = (rows: any) => {
    setNewRows([]);
    const payloadRows = rows.map((row: any) => {
      const { UID, isNewRow, ...rest } = row;
      return rest;
    });
    mutateMasterChecklist({
      query: UPDATE_MASTER_CHECKLIST,
      variables: {
        payload: payloadRows,
      },
    });
  };

  return (
    <StyledGrid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs={12}>
              <h1 className='mr-20'>Subscription Checklist - Master</h1>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <h5 className='font-wt-400 mt-2 mb-0'>
            A fund's subscription checklist lists all the documents required by the Fund Manager in
            order to accept a new subscription. This section is used to create platform-wide default
            checklists. You can add fund-specific requirements in each Fund's Create or Edit flow.
          </h5>
        </Container>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid item xs={12} mb={4}>
            <SubMasterListTable
              columns={COLUMNS}
              rows={rows}
              setUpdateRow={(row: any, index: number) => handleUpdateRows(row, index)}
              setDeleteRow={(obj: { id: number | string; row: any }) => handleDeleteRow(obj)}
              isLoading={isFetchingMasterChecklist || isRefetching}
              isDeleting={deletingChecklist}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiButton
              variant='text'
              buttonClick={() => {
                const newRow = {
                  title: '',
                  type: '',
                  visible_to_natural_persons: '',
                  visible_to_entities: '',
                  visible_to_hybrids: '',
                  UID: `${count}-${new Date().getTime()}`,
                  isNewRow: true,
                };
                setRows([...rows, newRow]);
              }}
            >
              Add Row
            </MuiButton>
          </Grid>
          <Grid item xs={12} mb={8} className='flex-center-end'>
            <MuiButton variant='text' buttonClick={() => navigate('/admin')}>
              Discard Changes
            </MuiButton>
            {mutatingChecklist ? (
              <LoadingButton minWidth='150px' className='ml-3' />
            ) : (
              <MuiButton
                variant='contained'
                minWidth='150px'
                className='ml-3'
                disabled={isDisabled}
                buttonClick={() => {
                  handleUpdateMasterChecklist(rows);
                }}
              >
                Save Changes
              </MuiButton>
            )}
          </Grid>
        </Container>
      </Grid>
    </StyledGrid>
  );
};

export default SubscriptionMasterChecklist;
