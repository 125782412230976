import React, { useEffect, useState } from 'react';
import { Done, KeyboardArrowDown } from '@mui/icons-material';
import { Container, Fade, Grid, Menu, MenuItem } from '@mui/material';

import MuiButton from 'common/components/button';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import MuiTable from 'common/components/table/TableV2';
import { FUNDS_COLUMNS, FUNDS_CRUMBS, STATUS_FILTERS, STATUS_LIST } from './constants';
import { useManageFundQuery } from 'common/hooks/investments/useManageFundQuery';
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from 'common/constants';

const ManageFunds = () => {
  const [currentFilter, setCurrentFilter] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilters = Boolean(anchorEl);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [fundData, setFundData] = useState<Array<any>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFundListVariables = () => {
    return {
      payload: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: pageNo,
        isManageFunds: true,
        search: {
          query: searchQuery,
          fund_status: currentFilter
            ? currentFilter[0] === 'Approval Requested'
              ? ['Onboarding - Awaiting Approval']
              : currentFilter
            : STATUS_LIST,
        },
      },
    };
  };

  const { data, isLoading } = useManageFundQuery(
    getFundListVariables,
    pageNo,
    searchQuery,
    currentFilter,
  );

  const setStatus = (status: String) => {
    if (status === 'Closed to New Investment') {
      return 'Closed for Subscription';
    } else if (status === 'InActive') {
      return 'Inactive';
    } else if (status === 'Onboarding - Awaiting Approval') {
      return 'Approval Requested';
    } else {
      return status;
    }
  };

  const handleModifiedData = (data: any) => {
    return data.map((obj: any) => {
      return { ...obj, fund_status: `${setStatus(obj.fund_status)}` };
    });
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      setFundData(handleModifiedData(data?.data));
      setTotalCount(data?.totalCount);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleApplyStatusFilter = (type: any) => {
    setPageNo(DEFAULT_PAGE_NO);
    if (type && type === 'All') {
      setCurrentFilter(STATUS_LIST);
    } else setCurrentFilter([type]);
    handleClose();
  };

  const handleSearch = (search: string) => {
    setPageNo(DEFAULT_PAGE_NO);
    setSearchQuery(search);
  };

  const handlePagination = (value: number) => {
    setPageNo(value);
  };

  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={FUNDS_CRUMBS} className='activityList'>
          <Grid container justifyContent='space-between' alignItems='end'>
            <Grid item xs='auto'>
              <h1 className='mb-0 mr-20'>Manage Funds</h1>
            </Grid>
            <Grid item container xs='auto'>
              <Grid item xs='auto' mr={2}>
                <SearchBar
                  minWidth='275px'
                  searchQuery={searchQuery}
                  setSearchQuery={(search: string) => {
                    handleSearch(search);
                  }}
                />
              </Grid>
              <Grid item xs='auto'>
                <MuiButton className='filterMainBtn' buttonClick={handleClick}>
                  {currentFilter ? (currentFilter.length > 1 ? 'All' : currentFilter) : 'Status'}{' '}
                  <KeyboardArrowDown sx={{ fontSize: '16px', ml: '11px' }} />
                </MuiButton>
                <Menu
                  id='fade-menu'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openFilters}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {React.Children.toArray(
                    STATUS_FILTERS.map((type: string, t: number) => (
                      <MenuItem
                        className='flex-center-start'
                        key={`statusFilter${t}`}
                        onClick={() => handleApplyStatusFilter(type)}
                      >
                        <Done
                          fontSize='small'
                          className={`mr-2`}
                          sx={{ opacity: currentFilter === type ? 1 : 0 }}
                        />{' '}
                        {type}
                      </MenuItem>
                    )),
                  )}
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <MuiTable
            columns={FUNDS_COLUMNS}
            rows={fundData}
            canRedirect
            redirectURLPrefix='investment/'
            redirectURLKey='fund_id'
            rowClassName='cursor-pointer'
            isLoading={isLoading}
            rowsPerPage={Number(totalCount)}
            handleSetPage={handlePagination}
            page={pageNo}
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export default ManageFunds;
