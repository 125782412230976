import { Grid } from '@mui/material';
import Loading from 'common/components/Loading';
import { ReactQueryDataFetch } from 'common/types';
import { useEffect, useState } from 'react';
import { useAppSelector, useGraphQLQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { Events as EventType } from 'common/types';
import Events from './Events';
import Funds from './Funds';
import Hero from './Hero';
import { UserProfile } from 'store/user/types';
import Themes from './Themes';
import { THEMES_DATA } from 'common/constants/betaData';
import { GET_ACTIVE_CLIENTS, GET_FUND_THEMES_DATA, RECOMMENDED_FUND_QUERY } from './queries';
import Recommendations from './Recommendations';
import {
  setEngineNumber,
  setIsLandingPage,
  setRecommendationActiveStep,
  setRecommendationClient,
} from 'store/recommendation/slice';
import { GET_FUNDS_LIST } from 'common/hooks/investments/queries';

const Intro = () => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [filteredEvents, setFilteredEvents] = useState<Array<EventType>>([]);
  const [themes, setThemes] = useState<Array<any>>([]);
  const showFunds =
    process.env.REACT_APP_SHOW_RECOMMENDED_FUNDS?.toString().toLowerCase() === 'true';

  const { data: activeClients = [], isLoading: activeClientsLoading } = useGraphQLQuery(
    `activeClientList${user?.userId}`,
    {
      baseURL: process.env.REACT_APP_NODE_INVESTOR_SERVER_BASE_URL as string,
      query: GET_ACTIVE_CLIENTS,
      variables: {
        userId: user?.userId,
      },
    },
    { enabled: !!user, refetchOnWindowFocus: false },
  );

  const { data, isLoading: eventsLoading } = useReactQuery(
    [`dashboard${user?.userId}`],
    {
      url: 'qaip/v1/notificationmanagement/events/dashboard',
    },
    {
      retryOnMount: true,
      cacheTime: 0,
    },
  ) as { data: EventType[] } & ReactQueryDataFetch;

  useEffect(() => {
    if (data) {
      setFilteredEvents(
        data.filter(
          (event: EventType) =>
            new Date((event.endTime as string).replace(/-/g, '/')) >= new Date(),
        ),
      );
    }
  }, [data]);

  const { data: recommendedFunds } = useGraphQLQuery(
    'recommendedFunds',
    {
      baseURL: 'https://qualis-fund-api-dot-qualis-dev.uc.r.appspot.com',
      query: RECOMMENDED_FUND_QUERY,
      variables: {
        investerId: '',
        fundId: '',
        userId: user?.userId,
        categoryId: user?.category,
        organisationId: (user as any)?.organisationId,
      },
    },
    { enabled: !!user },
  );

  const getFundListVariables = () => {
    return {
      payload: {
        pageSize: 1,
        sortBy: 'last_updated_date',
      },
    };
  };

  const {
    data: { getFunds: { data: fundData } = { data: [] } } = {},
    isLoading: isLatestFundsLoading,
  } = useGraphQLQuery([`getFundList`], {
    baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
    query: GET_FUNDS_LIST,
    variables: getFundListVariables(),
  });

  const { data: fundThemesData } = useGraphQLQuery(
    `fundThemesData`,
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUND_THEMES_DATA,
    },
    { refetchOnMount: true },
  );

  const { data: clientList, isLoading: isClientListLoading } = useReactQuery(
    [`clientList${user?.userId}`],
    {
      url: 'qaip/v1/investormanagement/investors',
    },
  );

  useEffect(() => {
    if (fundThemesData && fundThemesData.getTheamesFundCount.length > 0) {
      setThemes(
        fundThemesData.getTheamesFundCount.map((t: any) => ({
          ...t,
          themeImageUrl: THEMES_DATA.find((d: any) => d.themeName === t.themeName)?.themeImageUrl,
        })),
      );
    }
  }, [fundThemesData]);

  useEffect(() => {
    dispatch(setEngineNumber({ engineNumber: 0 }));
    dispatch(setRecommendationActiveStep({ activeStep: 0 }));
    dispatch(setIsLandingPage({ isLandingPage: true }));
    dispatch(setRecommendationClient({ client: null }));
    //eslint-disable-next-line
  }, []);

  const isRecommendationEngineVisible =
    process.env.REACT_APP_SHOW_RECOMMENDATION_ENGINE_FLOW === 'true';

  if (!userProfile) return null;

  if (eventsLoading || activeClientsLoading || isClientListLoading || isLatestFundsLoading)
    return <Loading />;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Hero
          totalClients={user?.totalClients as number}
          clients={activeClients?.getActiveInvestors}
          totalAum={user?.aum as number}
          name={user?.firstName as string}
          userName={user?.userName as string}
        />
      </Grid>

      {isRecommendationEngineVisible && clientList?.length > 0 && (
        <Grid item container xs={12}>
          <Recommendations
            clientList={clientList}
            latestFund={fundData?.length > 0 ? fundData[0] : null}
            fund={recommendedFunds?.getRecFunds[0]}
            className='container-lr-padding'
            heading='Recommended for your clients'
          />
        </Grid>
      )}

      {themes?.length > 0 && (
        <Grid item container xs={12}>
          <Themes list={themes} />
        </Grid>
      )}

      {showFunds && (
        <Grid item container xs={12}>
          <Funds
            list={recommendedFunds?.getRecFunds}
            className='container-lr-padding'
            heading='Based On Your Activity'
          />
        </Grid>
      )}
      {filteredEvents?.length > 0 && (
        <Grid item container xs={12}>
          <Events
            list={filteredEvents.sort((a: EventType, b: EventType) =>
              (a.starTime as string).localeCompare(b.starTime as string),
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Intro;
